import React, { useState, useEffect } from "react";
import "./CashierCheckout.scss";
import { Card, Typography, Select, Divider, Button } from "antd";
import {
  CheckCircleOutlined,
  EditOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { ShippingSelect } from "../../../../components";
import { useDispatch, useSelector } from "react-redux";
import { resetPatientError } from "../../../../features/patient/patientSLice";
import {
  patchPatientShippingAddress,
  getPatientDetails,
  postPatientShippingAddress,
  getPatientShippingAddresses,
} from "../../../../features/patient/patientActions";
import { makeOrder } from "../../../../features/order/orderActions";
import Utils from "../../../../utils";

export default function CashierCheckout({
  token,
  isMobile,
  selectedPromoCode,
  setSelectedPromoCode,
  orderCalculation,
  openNotificationWithIcon,
}) {
  const { items, setCard } = useSelector((state) => state?.card);
  const { isAuthenticated, info: authenticatedPatient } = useSelector(
    (state) => state?.auth
  );
  const {
    codes: { results: promoCodes },
    loading: promoCodesLoading,
  } = useSelector((state) => state?.promoCodes);
  const {
    patient,
    shipping_addresses: { results: shipping_addresses },
    loading,
    error,
  } = useSelector((state) => state?.patient);
  const dispatch = useDispatch();
  const [isOnEditMode, setIsOnEditMode] = useState(false);
  const [initialAddress, setInitialAddress] = useState(undefined);
  const [selectedShippingAddress, setSelectedShippingAddress] =
    useState(undefined);
  const [orderLoading, setOrderLoading] = useState(false);
  const addresses = [
    {
      id: "current",
      address1: patient?.address ? patient?.address : "",
      state: patient?.state ? patient?.state : "",
      city: patient?.city ? patient?.city : "",
      zipcode: patient?.zipcode ? patient?.zipcode : "",
      address2: patient?.address2 ? patient?.address2 : "",
    },
    ...(shipping_addresses || []),
  ];

  const foundPromoCode = selectedPromoCode
    ? promoCodes?.find(
        (code) => code?.id?.toString() === selectedPromoCode?.value?.toString()
      )
    : null;

  const getPayementDetailPrice = (price) => {
    if (!isNaN(price)) {
      return Utils?.roundNumber(price);
    }
  };

  const showTaxes = (taxes) => {
    const res = [];
    let i = 0;
    for (let key in taxes) {
      if (key !== "discount_total" && key !== "grand_total") {
        const formattedKey = key
          ?.split("_") // Split key by underscores
          ?.map(
            (word) =>
              word?.charAt(0)?.toUpperCase() + word?.slice(1)?.toLowerCase()
          ) // Capitalize each word
          ?.join(" ");
        if (key === "promo_code") {
          res?.push(
            <div className="cashierCheckout-cont__textWrapper" key={i}>
              <Typography.Text
                className="cashierCheckout-cont__text"
                style={{
                  color: token?.Typography?.colorPrimaryText,
                }}>
                {formattedKey}
              </Typography.Text>
              <Typography.Text
                className="cashierCheckout-cont__title"
                style={{
                  color: token?.Typography?.colorPrimaryText,
                }}>
                {taxes[key] || taxes[key] === 0
                  ? `${
                      taxes[key] === 0
                        ? getPayementDetailPrice(taxes[key])
                        : "-" + getPayementDetailPrice(taxes[key])
                    }`
                  : "$0"}
              </Typography.Text>
            </div>
          );
        } else {
          res?.push(
            <div className="cashierCheckout-cont__textWrapper" key={i}>
              <Typography.Text
                className="cashierCheckout-cont__text"
                style={{
                  color: token?.Typography?.mainColorText,
                }}>
                {formattedKey}
              </Typography.Text>
              <Typography.Text
                className="cashierCheckout-cont__title"
                style={{
                  color: token?.Typography?.mainColorText,
                }}>
                {taxes[key] || taxes[key] === 0
                  ? getPayementDetailPrice(taxes[key])
                  : "$0"}
              </Typography.Text>
            </div>
          );
        }

        i += 1;
      }
    }
    return res;
  };

  const updateShippingAddress = (params) => {
    if (params?.addressId !== "current") {
      dispatch(
        patchPatientShippingAddress({
          id: params?.id,
          addressId: params?.addressId,
          default: params.default,
        })
      ).then((res) => {
        if (res.type === "patch_patient_shiping_address/fulfilled") {
          dispatch(getPatientDetails({ id: params?.id }));
          setIsOnEditMode(false);
        }
      });
    } else {
      setIsOnEditMode(false);
    }
  };

  useEffect(() => {
    setInitialAddress({
      id: "current",
      address1: patient?.address ? patient?.address : "",
      state: patient?.state ? patient?.state : "",
      city: patient?.city ? patient?.city : "",
      zipcode: patient?.zipcode ? patient?.zipcode : "",
      address2: patient?.address2 ? patient?.address2 : "",
    });
  }, [
    patient?.address,
    patient?.address2,
    patient?.state,
    patient?.city,
    patient?.zipcode,
  ]);

  useEffect(() => {
    if (isAuthenticated && authenticatedPatient?.id) {
      dispatch(
        getPatientDetails({
          id: authenticatedPatient?.id ? authenticatedPatient?.id : "",
          // id: 2580,
        })
      );
      dispatch(
        getPatientShippingAddresses({
          id: authenticatedPatient?.id ? authenticatedPatient?.id : "",
          // id: 2580,
        })
      );
    }
  }, [dispatch, isAuthenticated, authenticatedPatient?.id]);

  const checkout = () => {
    setOrderLoading(true);
    dispatch(
      makeOrder({
        customer: patient?.id,
        // customer: "2580",
        phone_number: patient?.phone_number, // TODO check
        // phone_number: "+17609874802",
        order_items: items?.map((el) => ({
          menu_item: el?.menu_item,
          quantity: el?.quantity,
          variant: el?.variant,
        })),
        grand_total: orderCalculation?.grand_total,
        address:
          selectedShippingAddress && selectedShippingAddress?.id !== "current"
            ? `${
                selectedShippingAddress?.address1
                  ? `${selectedShippingAddress?.address1},`
                  : ""
              } ${
                selectedShippingAddress?.address2
                  ? `${selectedShippingAddress?.address2},`
                  : ""
              } ${
                selectedShippingAddress?.city
                  ? `${selectedShippingAddress?.city},`
                  : ""
              } ${
                selectedShippingAddress?.state
                  ? `${selectedShippingAddress?.state},`
                  : ""
              } ${
                selectedShippingAddress?.zipcode
                  ? selectedShippingAddress?.zipcode
                  : ""
              }`
            : undefined,
        promo_code: selectedPromoCode ? selectedPromoCode?.value : undefined,
      })
    ).then((res) => {
      if (res) {
        setOrderLoading(false);
        if (res?.type === "makeOrder/fulfilled") {
          setSelectedPromoCode(undefined);
          dispatch(setCard([]));
          openNotificationWithIcon(
            "success",
            "Create Order",
            "Your order has been successfully created."
          );
        } else if (res?.type === "makeOrder/rejected") {
          if (
            typeof res?.payload === "object" &&
            !Array.isArray(res?.payload)
          ) {
            for (let key in res?.payload) {
              if (
                res?.payload[key] &&
                res?.payload[key][0] &&
                typeof res?.payload[key][0] === "string"
              ) {
                openNotificationWithIcon("error", key, res?.payload[key]);
                break;
              } else {
                openNotificationWithIcon(
                  "error",
                  "Error",
                  "Your order has not been created."
                );
                break;
              }
            }
          } else {
            openNotificationWithIcon(
              "error",
              "Error",
              "Your order has not been created."
            );
          }
        }
      }
    });
  };

  return (
    <Card
      className="cashierCheckout-cont"
      style={{
        height: "fit-content",
        border: "none",
        backgroundColor: token?.Card?.colorBgCard,
      }}
      styles={{
        body: isMobile
          ? {
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              padding: "16px",
            }
          : {
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "24px",
              padding: "32px",
            },
      }}>
      <div className="cashierCheckout-cont__promoCodeWrapper">
        <Typography.Text
          className="cashierCheckout-cont__text"
          style={{
            color: token?.Typography?.mainColorText,
          }}>
          Promo Code
        </Typography.Text>
        <Select
          className="cashierCheckout-cont__promoCodeWrapper__promoInp"
          placeholder="Promo Code"
          value={selectedPromoCode ? selectedPromoCode : undefined}
          options={promoCodes?.map((promoCode) => ({
            label: promoCode?.code ? promoCode?.code : "",
            value: promoCode?.id ? promoCode?.id : "",
          }))}
          allowClear
          onClear={() => {
            setSelectedPromoCode(undefined);
          }}
          onChange={(_, val) => {
            setSelectedPromoCode(val);
          }}
          disabled={orderLoading}
          loading={promoCodesLoading}
        />
        {selectedPromoCode ? (
          <Typography.Text
            className="cashierCheckout-cont__text"
            style={{
              color: token?.Typography?.colorPrimaryText,
              fontSize: "14px",
            }}
            ellipsis={{
              tooltip: {
                title: foundPromoCode?.description
                  ? foundPromoCode?.description
                  : "No Description",
              },
            }}>
            {foundPromoCode?.description
              ? foundPromoCode?.description
              : "No Description"}
          </Typography.Text>
        ) : null}
      </div>
      <Divider
        type="horizontal"
        className="cashierCheckout-cont__divider"
        style={{
          borderColor: token?.Layout?.footerBorderTopColor,
        }}
      />
      <div className="cashierCheckout-cont__keyValPairWrapper">
        <Typography.Text
          className="cashierCheckout-cont__text"
          style={{
            color: token?.Typography?.mainColorText,
          }}>
          Order Summary
        </Typography.Text>
        {showTaxes(orderCalculation)}
      </div>
      <Divider
        type="horizontal"
        className="cashierCheckout-cont__divider"
        style={{
          borderColor: token?.Layout?.footerBorderTopColor,
        }}
      />
      <div className="cashierCheckout-cont__shippingAddressCont">
        <div className="cashierCheckout-cont__textWrapper">
          <Typography.Text
            className="cashierCheckout-cont__text"
            style={{
              color: token?.Typography?.mainColorText,
            }}>
            Shipping Address
          </Typography.Text>
          {isOnEditMode ? (
            <div style={{ display: "flex", gap: "8px" }}>
              <Button
                type="text"
                size="small"
                disabled={!initialAddress || loading || error}
                loading={loading}
                icon={
                  <CheckCircleOutlined
                    style={{
                      fontSize: "18px",
                      color: token?.icons?.colorPrimaryIcon,
                    }}
                  />
                }
                onClick={() => {
                  setSelectedShippingAddress(initialAddress);
                  updateShippingAddress({
                    id: patient.id,
                    addressId: initialAddress?.id,
                    default: true,
                  });
                }}
              />
              <Button
                type="text"
                size="small"
                disabled={loading}
                icon={
                  <CloseCircleOutlined
                    style={{
                      fontSize: "18px",
                      color: token?.icons?.whiteIcon,
                    }}
                  />
                }
                onClick={() => {
                  if (error) {
                    dispatch(resetPatientError());
                  }
                  setIsOnEditMode(false);
                  if (selectedShippingAddress) {
                    setInitialAddress(selectedShippingAddress);
                  } else {
                    setInitialAddress({
                      id: "current",
                      address1: patient?.address ? patient?.address : "",
                      state: patient?.state ? patient?.state : "",
                      city: patient?.city ? patient?.city : "",
                      zipcode: patient?.zipcode ? patient?.zipcode : "",
                      address2: patient?.address2 ? patient?.address2 : "",
                    });
                  }
                }}
              />
            </div>
          ) : (
            <Button
              type="text"
              size="small"
              loading={loading}
              icon={
                <EditOutlined
                  style={{
                    fontSize: "18px",
                    color: token?.icons?.colorPrimaryIcon,
                  }}
                />
              }
              onClick={() => {
                if (error) {
                  dispatch(resetPatientError());
                }
                setIsOnEditMode(true);
              }}
            />
          )}
        </div>
        {isOnEditMode ? (
          <ShippingSelect
            className="cashierCheckout-cont__shippingAddressCont__shippingSelect"
            token={token}
            placeholder={"Shipping Address"}
            addBtnText="Add"
            loading={loading}
            options={addresses}
            selectedItem={initialAddress}
            setSelectedItem={setInitialAddress}
            addItem={(details) => {
              dispatch(
                postPatientShippingAddress({
                  ...details,
                  id: patient?.id,
                })
              ).then((res) => {
                if (res.type === "post_patient_shiping_address/rejected") {
                  let error = res?.payload;
                  for (let key in error) {
                    openNotificationWithIcon("error", key, error[key]);
                  }
                  setTimeout(() => {
                    dispatch(resetPatientError());
                  }, 2000);
                }
              });
            }}
            error={error}
            resetError={() => {
              dispatch(resetPatientError());
            }}
          />
        ) : (
          <Typography.Text className="cashierCheckout-cont__shippingAddressCont__text">
            {selectedShippingAddress
              ? `${
                  selectedShippingAddress?.address1
                    ? `${selectedShippingAddress?.address1},`
                    : ""
                } ${
                  selectedShippingAddress?.address2
                    ? `${selectedShippingAddress?.address2},`
                    : ""
                } ${
                  selectedShippingAddress?.city
                    ? `${selectedShippingAddress?.city},`
                    : ""
                } ${
                  selectedShippingAddress?.state
                    ? `${selectedShippingAddress?.state},`
                    : ""
                } ${
                  selectedShippingAddress?.zipcode
                    ? selectedShippingAddress?.zipcode
                    : ""
                }`
              : `${patient?.address ? `${patient?.address},` : ""}
                ${patient?.address2 ? `${patient?.address2},` : ""} 
                ${patient?.city ? `${patient?.city},` : ""}
                ${patient?.state ? `${patient?.state},` : ""}
                ${patient?.zipcode ? patient?.zipcode : ""}`}
          </Typography.Text>
        )}
      </div>
      <Divider
        type="horizontal"
        className="cashierCheckout-cont__divider"
        style={{
          borderColor: token?.Layout?.footerBorderTopColor,
        }}
      />
      <div className="cashierCheckout-cont__textWrapper">
        <Typography.Text
          className="cashierCheckout-cont__text"
          style={{
            color: token?.Typography?.mainColorText,
          }}>
          Total
        </Typography.Text>
        <Typography.Text
          className="cashierCheckout-cont__title"
          style={{
            color: token?.Typography?.mainColorText,
          }}>
          {orderCalculation?.grand_total || orderCalculation?.grand_total === 0
            ? getPayementDetailPrice(orderCalculation?.grand_total)
            : "$0"}
        </Typography.Text>
      </div>
      <Button
        onClick={() => {
          checkout();
        }}
        type="primary"
        disabled={!items?.length}
        loading={orderLoading}>
        Proceed To Checkout
      </Button>
    </Card>
  );
}
