import React from "react";
import "./PromoCard.scss";
import { theme, Card, Typography, Divider } from "antd";

export default function PromoCard({ promoCode }) {
  const { useToken } = theme;
  const { token } = useToken();

  return (
    <Card
      key={promoCode?.id}
      className="promoCard-cont"
      style={{
        borderLeftColor: token?.colorPrimary,
        backgroundColor: token?.Card?.colorBgCard,
      }}
      styles={{
        body: {
          padding: "8px",
        },
      }}>
      <div className="promoCard-cont__bodyCont">
        <div className="promoCard-cont__bodyCont__textWrapper">
          <div>
            <Typography.Text
              className="promoCard-cont__bodyCont__textWrapper__text"
              style={{
                color: token?.Typography?.mainColorText,
              }}
              ellipsis={{
                tooltip: {
                  title: promoCode?.code ? promoCode?.code : "",
                },
              }}>
              {promoCode?.code ? promoCode?.code : ""}
            </Typography.Text>
          </div>
        </div>
        <Divider
          type="horizontal"
          className="promoCard-cont__bodyCont__divider"
          style={{
            borderColor: token?.Layout?.footerBorderTopColor,
          }}
        />
        <div className="promoCard-cont__bodyCont__textWrapper">
          <div
            style={{
              width: "100%",
            }}>
            <Typography.Text
              className="promoCard-cont__bodyCont__textWrapper__text"
              style={{
                color: token?.Typography?.mainColorText,
              }}
              ellipsis={{
                tooltip: {
                  title: promoCode?.description ? promoCode?.description : "",
                },
              }}>
              {promoCode?.description ? promoCode?.description : ""}
            </Typography.Text>
          </div>
        </div>
      </div>
    </Card>
  );
}
