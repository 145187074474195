import React, { useState } from "react";
import "./CashierCard.scss";
import { Card, Typography, Image, Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { Counter } from "../../../../components";
import logo from "../../../../assets/CanaSaleLogo.svg";
import { useSelector } from "react-redux";
import useCard from "../../../../hooks/useCard";
import { ConfirmModal } from "../../../../modals";
import Utils from "../../../../utils";

export default function CashierCard({ token }) {
  const { items } = useSelector((state) => state?.card);
  const [selectedItem, setSelectedItem] = useState(false);
  const [isConfirmModalOpned, setIsConfirmModalOpened] = useState(false);
  const { editCard } = useCard();

  return items?.length ? (
    <div className="cashierCard-cont">
      {items?.map((product) => (
        <Card
          key={product?.key}
          className="cashierCard-cont__card"
          style={{
            border: "none",
            backgroundColor: token?.Card?.colorBgCard,
          }}
          styles={{
            body: {
              width: "100%",
              display: "flex",
              gap: "12px",
              padding: "8px",
            },
          }}>
          <Image
            src={
              product?.details?.picture_url
                ? product?.details?.picture_url
                : logo
            }
            width="56px"
            height="56px"
          />
          <div className="cashierCard-cont__card__descriptionCont">
            <div className="cashierCard-cont__card__descriptionCont__productCont">
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  gap: "4px",
                }}>
                <Typography.Text
                  className="cashierCard-cont__card__descriptionCont__productCont__text"
                  style={{
                    color: token?.Typography?.mainColorText,
                  }}>
                  {product?.details?.name ? product?.details?.name : ""}
                </Typography.Text>
                <Button
                  size="small"
                  icon={
                    <DeleteOutlined
                      style={{
                        fontSize: "18px",
                      }}
                    />
                  }
                  onClick={() => {
                    setSelectedItem(product);
                    setIsConfirmModalOpened(true);
                  }}
                />
              </div>

              <Typography.Text
                className="cashierCard-cont__card__descriptionCont__productCont__text"
                style={{
                  color: token?.Typography?.mainColorText,
                }}>
                {product?.variant?.name && product?.variant?.unit
                  ? Utils?.showProductDetail(product?.variant)
                  : ""}
              </Typography.Text>
              <div className="cashierCard-cont__card__descriptionCont__productCont__textWrapper">
                {product?.details?.sale && product?.variant?.price ? (
                  <div
                    style={{
                      display: "flex",
                      gap: "4px",
                      alignItems: "center",
                    }}>
                    <Typography.Text
                      className="cashierCard-cont__card__descriptionCont__productCont__text"
                      key={product?.variant?.id ? product?.variant?.id : ""}
                      style={{
                        color: token?.Typography?.colorPrimaryText,
                      }}
                      delete>
                      {product?.variant?.price
                        ? Utils?.roundNumber(product?.variant?.price)
                        : "$"}
                    </Typography.Text>
                    <Typography.Text
                      style={{
                        color: token?.Typography?.mainColorText,
                      }}
                      className="cashierCard-cont__card__descriptionCont__productCont__text"
                      key={product?.details?.sale?.id}>
                      {product?.variant?.price
                        ? Utils?.roundNumber(
                            Number(product?.variant?.price) -
                              Number(
                                (product?.variant?.price *
                                  product?.details?.sale?.amount) /
                                  100
                              )
                          )
                        : "$"}
                    </Typography.Text>
                  </div>
                ) : (
                  <Typography.Text
                    className="cashierCard-cont__card__descriptionCont__productCont__text"
                    style={{
                      color: token?.Typography?.mainColorText,
                    }}>
                    {product?.variant?.price
                      ? Utils?.roundNumber(product?.variant?.price)
                      : "$"}
                  </Typography.Text>
                )}
                <Typography.Text
                  className="cashierCard-cont__card__descriptionCont__productCont__title"
                  style={{
                    color: token?.Typography?.colorPrimaryText,
                  }}>
                  {product?.details?.sale &&
                  product?.variant?.price &&
                  product?.quantity
                    ? Utils?.roundNumber(
                        product?.quantity
                          ? product?.quantity *
                              (Number(product?.variant?.price) -
                                Number(
                                  (product?.variant?.price *
                                    product?.details?.sale.amount) /
                                    100
                                ))
                          : Number(product?.variant?.price) -
                              Number(
                                (product?.variant?.price *
                                  product?.details?.sale.amount) /
                                  100
                              )
                      )
                    : product?.variant?.price && product?.quantity
                    ? Utils?.roundNumber(
                        product?.variant?.price * Number(product?.quantity)
                      )
                    : "$"}
                </Typography.Text>
              </div>
            </div>
            <Counter
              count={Number(product?.quantity)}
              onCauntChnage={(val) => {
                const updatedTabledData = items?.map((el) =>
                  el?.key === product?.key ? { ...product, quantity: val } : el
                );
                editCard(updatedTabledData);
              }}
              allowDecrement={true}
              openConfirmModal={() => {
                setSelectedItem(product);
                setIsConfirmModalOpened(true);
              }}
              style={{
                padding: "0",
              }}
            />
          </div>
        </Card>
      ))}
      <ConfirmModal
        title="Are you sure ?"
        subTitle={"You want to delete the item ."}
        isOpened={isConfirmModalOpned}
        onCancel={() => {
          setIsConfirmModalOpened(false);
        }}
        onOk={() => {
          const newTableData = items?.filter(
            (el) =>
              el?.variant?.id?.toString() !==
              selectedItem?.variant?.id?.toString()
          );
          setIsConfirmModalOpened(false);
          editCard(newTableData);
        }}
        token={token}
      />
    </div>
  ) : null;
}
